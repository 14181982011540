<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
				<div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br> {{desc2}}<br>{{desc3}}</p>
                    </div>
					<div class="d-flex flex-column">
						<b-button variant="primary pl-5 pr-5" class="mt-2 mr-1" @click="collectGrowthSales">로켓그로스 판매현황 수집</b-button>
						<b-button variant="success pl-5 pr-5" class="mt-2 mr-1" v-b-modal.datalabAPI>데이터랩 API설정</b-button>
						<b-modal id="datalabAPI" size="sm" scrollable title="네이버 데이터랩 API설정" ok-only ok-title="닫기" class="detail-modal">
							<div>
								<p><a href="https://developers.naver.com/apps/#/register=datalab" target="_blank"><u>네이버개발자센터</u></a>에 접속해서 아래의 순서로 API key를 받은 후 입력하고 API저장을 눌러주세요</p>
								<card class="w-50 p-3">
									<div class="d-flex justify-content-start align-items-center w-100">
										<div class="w-25">Client ID : </div>
										<div class="w-75">
											<input type="text" class="form-control text-center" placeholder="Client ID" v-model="clientId">
										</div>
									</div>
									<div class="d-flex justify-content-start align-items-center w-100">
										<div class="w-25">Client Secret : </div>
										<div class="w-75">
											<input type="text" class="form-control text-center" placeholder="Client Secret" v-model="clientSecret">
										</div>
									</div>
									<b-button class="w-25 mt-3" variant="outline-success" @click="saveAPI">API저장</b-button>
								</card>
							</div>


							<img  style="max-width:100%;" :src="require('../../../assets/images/small/데이터랩api.png')">
						</b-modal>
					</div>

                </div>
			</div>
			<div class="col-lg-12">
                <img v-if="showspinner" style="max-width:100%;" :src="require('../../../assets/images/small/spinner.gif')">
                <div v-if="showspinner1" class="d-flex justify-content-center">
                    <div><img style="max-width:10%;" :src="require('../../../assets/images/small/loading.gif')">{{loadingtext}}</div>
                </div>
            </div>

			<div class="col-lg-12" v-if="showlist">
				<div class="row">
					<!-- <b-button variant="outline-success" class="mb-1 mr-2" @click="showtotal">
					총 상품수<b-badge variant="success" class="ml-2">{{productqty}}</b-badge>
					</b-button>
					<b-button variant="outline-primary" class="mb-1 mr-2" @click="showsalescnt">
					총 판매수량<b-badge variant="primary" class="ml-2">{{salescnt}}</b-badge>
					</b-button>
					<b-button variant="outline-warning" class="mb-1 mr-2" @click="showimportcnt">
					총 입고수량<b-badge variant="warning" class="ml-2">{{importcnt}}</b-badge>
					</b-button> -->
					<!-- <b-button variant="outline-success" class="mb-1 mr-2" @click="showSalesAmount">
					총 판매매출(쿠팡)<b-badge variant="success" class="ml-2">{{salesAmount}}</b-badge>
					</b-button> -->
					<!-- <b-button variant="outline-danger" class="mb-1 mr-2" @click="showMarginAmount">
					총 수익<b-badge variant="danger" class="ml-2">{{marginAmount}}</b-badge>
					</b-button> -->
					<b-button variant="outline-secondary" class="ml-3 mb-1 mr-2" @click="showNeedPo">
					구매 필요<b-badge variant="secondary" class="ml-2">{{needPo}}</b-badge>
					</b-button>
					<!-- <b-button variant="outline-danger" class="mb-1 mr-2" @click="showNeedPriceAdjust">
					가격조정 필요<b-badge variant="danger" class="ml-2">{{needPriceAdjust}}</b-badge>
					</b-button>  -->
				</div>
			</div>

			<div class="col-lg-12 mt-3" v-if="showlist">
                <CDataTable :headers="headers" :items="saleslists" v-model="tableSelectedItem" :show-select="true" itemKey="_id" style="margin-bottom: 10px">
					<template v-slot:썸네일="{item}">
                        <img :src="item.썸네일" class="img-fluid rounded avatar-40 mr-3" alt="image">
                    </template>
                    <template v-slot:판매상품명="{item}">
                        <div class="d-flex align-items-center">{{item.판매상품명}}</div>
                    </template>
					<template v-slot:옵션명="{item}">
                        <div class="d-flex align-items-center">{{item.옵션명}}</div>
                    </template>
                    <template v-slot:타겟키워드="{item}">
                        <input type="text" class="form-control ml-1 text-center" placeholder="" v-model="item.타겟키워드" @keyup.enter="changePoEnter($event,item)">
                    </template>
                    <template v-slot:구매필요수량="{item}">
                        <div v-b-modal="'poQty'+item.바코드">
                            {{item.구매필요수량}}
                        </div>
                        <b-modal :id="'poQty'+item.바코드" scrollable title="구매필요수량" ok-title="OK" size="xl" class="detail-modal" @show="resetChartData(item)" ok-only @ok="closeModal($event,item)">
							<div class="row h-100">
								<div class="col-md-8 h-100">
									<b-card no-body>
										<b-card-body class="p-3"> <!-- padding을 제거합니다 -->
											<div style="width: 100%; height:100%"> <!-- 100% 너비의 div로 감쌉니다 -->
												<ApexChart ref="mainChart" element="layout1-chart-5" :chartOption="getUpdatedChartOption(item)" />
											</div>
										</b-card-body>
										<b-card-footer>
											<div class="d-flex justify-content-between align-items-center row">
												<div class="col-6 d-flex justify-content-start align-items-center">
													<label class="w-50">판매인식기간:</label>
													<b-form-input class="w-50" type="date" v-model="item.startDate" @change="updateDateRange(item)"></b-form-input>
													<h5> ~ </h5>
													<b-form-input class="w-50" type="date" v-model="item.endDate" @change="updateDateRange(item)"></b-form-input>
												</div>
												<div class="col-4 d-flex justify-content-start align-items-center">
													<b-button variant="outline-primary" size="md" @click="setDateRange(item, 7)">최근 7일</b-button>
													<b-button variant="outline-primary" size="md" @click="setDateRange(item, 30)" class="ml-2">최근 30일</b-button>
												</div>
											</div>
										</b-card-footer>
									</b-card>
								</div>
								<div class="col-md-4 h-100">
									<b-card title="판매 정보" class="mb-3">
										<b-list-group flush>
											<b-list-group-item class="d-flex justify-content-between align-items-center">
												<span>일평균판매량:</span>
												<b>{{ item.일판매수량.toFixed(1) }}</b>
											</b-list-group-item>
											<b-list-group-item class="d-flex justify-content-between align-items-center">
												<span>현재재고수량:</span>
												<b>{{ item.재고 }}</b>
											</b-list-group-item>
											<b-list-group-item class="d-flex justify-content-between align-items-center">
												<span>남은 재고기간:</span>
												<b>{{ item.남은재고기간 }}</b>
											</b-list-group-item>
										</b-list-group>
									</b-card>
									<b-card title="예측 정보" class="mb-3">
										<b-list-group flush>
											<b-list-group-item class="d-flex justify-content-between align-items-center">
												<span>향후 30일 예상 판매수량(series):</span>
												<b>{{ parseInt(item.forecastSeriesQty) }}</b>
											</b-list-group-item>
											<b-list-group-item class="d-flex justify-content-between align-items-center">
												<span>향후 30일 예상 판매수량(AI):</span>
												<b>{{ parseInt(item.forecastAIQty) }}</b>
											</b-list-group-item>
										</b-list-group>
									</b-card>
									<b-card title="구매 정보">
										<b-list-group flush>
											<b-list-group-item class="d-flex justify-content-between align-items-center">
												<span>구매D-Day:</span>
												<b>{{ item.dday }}</b>
											</b-list-group-item>
											<b-list-group-item class="d-flex justify-content-between align-items-center">
												<span>구매필요수량:</span>
												<b>{{ item.구매필요수량 }}</b>
											</b-list-group-item>
										</b-list-group>
									</b-card>
								</div>
							</div>
						</b-modal>
                    </template>
					<template v-slot:구매="{item}">
                        <input type="number" class="form-control ml-1 text-center w-100" placeholder="구매" v-model="item.구매" @keyup.enter="changePoEnter($event,item)">
                    </template>
                    <template v-slot:배대지="{item}">
                        <input type="number" class="form-control ml-1 text-center w-100" placeholder="배대지" v-model="item.배대지" @keyup.enter="changePoEnter($event,item)">
                    </template>
                    <template v-slot:입고중="{item}">
                        <input type="number" class="form-control ml-1 text-center w-100" placeholder="입고중" v-model="item.입고중" @keyup.enter="changePoEnter($event,item)">
                    </template>
					<template v-slot:국내재고="{item}">
                        <input type="number" class="form-control ml-1 text-center w-100" placeholder="국내재고" v-model="item.국내재고" @keyup.enter="changePoEnter($event,item)">
                    </template>
                    <template v-slot:입고기간="{item}">
                        <input type="number" class="form-control ml-1 text-center" placeholder="입고기간" v-model="item.입고기간" @keyup.enter="changePoEnter($event,item)">
                    </template>
                    <template v-slot:최대재고기간="{item}">
                        <input type="number" class="form-control ml-1 text-center" placeholder="최대재고기간" v-model="item.최대재고기간" @keyup.enter="changePoEnter($event,item)">
                    </template>
                    <template v-slot:최소재고기간="{item}">
                        <input type="number" class="form-control ml-1 text-center" placeholder="최소재고기간" v-model="item.최소재고기간" @keyup.enter="changePoEnter($event,item)">
                    </template>
                    <template v-slot:월판매수량="{item}">
                        <div v-b-modal="'poQty'+item.바코드">
                            {{item.월판매수량}}
                        </div>
                    </template>
                    <template v-slot:일판매수량="{item}">
                        <div v-b-modal="'poQty'+item.바코드">
                            {{item.일판매수량.toFixed(1)}}
                        </div>
                    </template>
                    <template v-slot:메모="{item}">
                        <input type="text" class="form-control ml-1 text-center" placeholder="" v-model="item.메모" @keyup.enter="changeMemo($event,item)">
                    </template>
                </CDataTable>
			<button type="button" class="btn btn-primary mr-2 mb-4" @click="saveSales">저장</button>
			<button type="button" class="btn btn-danger mr-2 mb-4" v-b-toggle.my-collapse>일괄변경</button>
			<!-- <button type="button" class="btn btn-success mr-2 mb-3" @click="delManageItems">숨김처리</button>
			<button type="button" class="btn btn-secondary mr-2 mb-3" v-b-modal.addManage>숨김처리취소</button> -->

			<b-collapse id="my-collapse">
				<b-card title="">
					<div class="form-group">
						<input type="number" class="form-control" placeholder="입고기간" v-model="입고기간">
					</div>
					<div class="form-group">
						<input type="number" class="form-control" placeholder="최소재고기간" v-model="최소재고기간">
					</div>
					<div class="form-group">
						<input type="number" class="form-control" placeholder="최대재고기간" v-model="최대재고기간">
					</div>
					<button type="button" class="btn btn-success mr-2 mt-1" @click="editlists">적용</button>
				</b-card>
			</b-collapse>  


            <!-- <button type="button" class="btn btn-primary mr-2 mb-4" @click="downloadsales">다운로드</button>
			<button type="button" class="btn btn-success mr-2 mb-4" @click="salesdownload">일자별 판매내역 다운로드</button> -->
			</div>
		</div>
		<!-- Page end  -->
	</div>
</template>
<script>
import axios from 'axios';
import ApexChart from '../../../components/charts/ApexChart';
// import { splice } from 'core-js/internals/enum-bug-keys';
import * as XLSX from 'xlsx';
import CDataTable from "@/components/common/CDataTable";
// import { nextTick } from 'vue/types/umd';
// import { json } from 'body-parser';
export default {
	name:'salesstatus',
	components:{
        ApexChart,
        CDataTable
    },
	data(){
		return{
            headers: [
				{text: '상품\n이미지', value: '썸네일', align: 'center', width: 50, isSlot: true},
                {text: '상품명', value: '판매상품명', align: 'center', width: 300, isSlot: true},
				{text: '옵션명', value: '옵션명', align: 'center', width: 150, isSlot: true},
                {text: '구매\nD-Day', value: 'dday', align: 'center', width: 80, isSlot: false},
                {text: '구매필요\n수량', value: '구매필요수량', align: 'center', width: 80, isSlot: true},
                {text: '타겟\n키워드', value: '타겟키워드', align: 'center', width: 120, isSlot: true},
                {text: '구매', value: '구매', align: 'center', width: 100, isSlot: true},
                {text: '배대지', value: '배대지', align: 'center', width: 100, isSlot: true},
                {text: '입고중', value: '입고중', align: 'center', width: 100, isSlot: true},
				{text: '국내재고', value: '국내재고', align: 'center', width: 100, isSlot: true},
                {text: '쿠팡재고', value: '재고', align: 'center', width: 80, isSlot: false},
                {text: '남은\n재고기간', value: '남은재고기간', align: 'center', width: 80, isSlot: false},
                {text: '입고기간', value: '입고기간', align: 'center', width: 100, isSlot: true},
                {text: '최대\n재고기간\n(구매기준\n기간)', value: '최대재고기간', align: 'center', width: 100, isSlot: true},
                {text: '최소\n재고기간', value: '최소재고기간', align: 'center', width: 100, isSlot: true},
                {text: '월판매\n수량', value: '월판매수량', align: 'center', width: 80, isSlot: true},
                {text: '일판매\n수량', value: '일판매수량', align: 'center', width: 80, isSlot: true},
                {text: '메모', value: '메모', align: 'center', width: 100, isSlot: true},
            ],
			tableSelectedItem: [],
			clientId: this.$store.state.settings[0].clientId ? this.$store.state.settings[0].clientId : '',
			clientSecret: this.$store.state.settings[0].clientSecret ? this.$store.state.settings[0].clientSecret : '',
			needPo:0,
			needPriceAdjust:0,
			salesAmount:0,
			marginAmount:0,

			입고기간:"",
			최소재고기간:"",
			최대재고기간:"",

			loadingtext:'',
			showspinner1:false,
			stddates:14,
			stdstockdates:30,
			importleadtimes:14,

			salesarr:[],
			importarr:[],
			stockarr:[],

			salescnt:0,
			importcnt:0,
			salesamount:0,
			supplyamount:0,
			noreview:0,
			productqty:0,
			nostock:0,
			// increview:0,
			// decreview:0,


			title:"로켓그로스 판매현황 및 SCM 관리",
			desc1:"로켓그로스의 판매현황을 확인하고 수요에 맞는 공급관리(SCM)를 진행하세요",
			desc2:"입고기간 : 입고하는 데까지 걸리는 기간",
			desc3:"최소재고기간 : 최소한의 재고가 남아있어야 하는 기간, 최대재고기간 : 구매 시 필요한 재고기준 기간",
			desc4:"",
			link:{ name: 'people.addusers'},
			linktext:"그로스 판매현황 수집",

			SKUID:'',
			showspinner: false,
			startdate: this.getTodaywith(),
			enddate: this.getTodaywith(),
			saleslists: [],
			saleslistsinit: [],
			showlist: false,

			UserListS:[
				{
					name:'Cliff Hanger',
					email:'cliff@gmail.com',
					company:'Product Manager',
					group:'Cliff',
					status:'Active',
					store:'1'
				},
				{
					name:'Terry Aki',
					email:'terry@gmail.com',
					company:'Stock CEO',
					group:'Terry',
					status:'Active',
					store:'2'
				}
			]
		}
	},
	mounted(){
		// this.getKeywordData(["2023-10-11","2023-10-10"],"후라이팬정리대")
		this.search();

		this.messageEventHandler = async(event) => {
			if (event.data.msg && event.data.msg === 'growthSales') {
                if (event.data.res == 'success') {
                    this.showlist = false;
                    this.loadingtext = '수집 상품 저장 중 ...';
                    console.log(event.data)
                    var stockInfo = event.data.stockInfo.arr;
					var impressions = event.data.stockInfo.impressions;

					if(stockInfo.length > 0){
						var sales = [];
						for(var e of stockInfo){
							if(impressions && impressions.find(f => f.vendorItemId == e.vendorItemId)){
								if(impressions.find(f => f.vendorItemId == e.vendorItemId).trafficInfo){
									var salesHistory = impressions.find(f => f.vendorItemId == e.vendorItemId).trafficInfo.saleSummaryByDate.map(e => e.unitsSold).slice(-30);
									var visitorHistory = impressions.find(f => f.vendorItemId == e.vendorItemId).trafficInfo.trafficSummaryByDate.map(e => e.uniqueVisitor).slice(-30);
									var pageViewHistory = impressions.find(f => f.vendorItemId == e.vendorItemId).trafficInfo.trafficSummaryByDate.map(e => e.pageViews).slice(-30);
									var cartHistory = impressions.find(f => f.vendorItemId == e.vendorItemId).trafficInfo.conversionSummaryByDate.map(e => e.addToCart).slice(-30);
									var orderHistory = impressions.find(f => f.vendorItemId == e.vendorItemId).trafficInfo.conversionSummaryByDate.map(e => e.orders).slice(-30);
									var pvToOrder = impressions.find(f => f.vendorItemId == e.vendorItemId).trafficInfo.conversionSummaryByDate.map(e => Math.round(e.pvToOrder*1000)/10).slice(-30);
									var prevMonthlyClicks = impressions.find(f => f.vendorItemId == e.vendorItemId).trafficInfo.trafficSummaryByDate.map(e => e.uniqueVisitor).slice(30).reduce((pv,cv)=>{return pv + cv},0);
									var currentMonthlyClicks = visitorHistory.reduce((pv,cv)=>{return pv + cv},0);
								} else {
									salesHistory = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
									visitorHistory = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
									pageViewHistory = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
									cartHistory = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
									orderHistory = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
									pvToOrder = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
									prevMonthlyClicks = 0;
									currentMonthlyClicks = 0;
								}
							}

							// var forecastAI = e.rrqData.salesForecast.split(',');

							//datalab 데이터 기준 보정해서 forecastAIDatalab array출력하는 함수 추가 필요
							// var forecastAIDatalab = e.rrqData.salesForecast.split(',');

							// forecastAI = forecastAI.map(e => parseFloat(e));
							// forecastAIDatalab = forecastAIDatalab.map(e => parseFloat(e));


							var 일판매수량 = salesHistory.reduce((pv,cv)=>{return pv+cv},0)/30;
							var 월판매수량 = salesHistory.reduce((pv,cv)=>{return pv+cv},0);

							var 재고 = e.orderableQty ? e.orderableQty : 0;


							// 0이 아닌 첫 번째 요소의 인덱스를 찾는다.
							let startIndex = salesHistory.findIndex(element => element !== 0);

							// 0이 아닌 요소가 없는 경우 (모든 요소가 0인 경우)
							if (startIndex === -1) {
								var mean = 0;
							} else {
								// startIndex부터 배열 끝까지의 요소들의 합계
								let sum = 0;
								for (let i = startIndex; i < salesHistory.length; i++) {
									sum += salesHistory[i];
								}

								// 평균을 계산한다
								mean = sum / (salesHistory.length - startIndex);
							}

							var forecastSeries = [];
							for(var i=0;i<입고기간+최대재고기간;i++){
								forecastSeries.push(mean);
							}




							if(this.$store.state.growthSales.length > 0 && this.$store.state.growthSales.find(f => f.바코드 == e.barcode)){
								var f = this.$store.state.growthSales.find(f => f.바코드 == e.barcode);
								var 최대재고기간 = parseInt(f.최대재고기간);
								var 최소재고기간 = parseInt(f.최소재고기간);
								var 입고기간 = parseInt(f.입고기간);
								var 구매 = parseInt(f.구매);
								var 배대지 = parseInt(f.배대지);
								var 입고중 = parseInt(f.입고중);
								var 국내재고 = f.국내재고 ? parseInt(f.국내재고) : 0;
								var 메모 = f.메모;

								var 가용재고 = 재고 + 구매 + 배대지 + 입고중 + 국내재고;
								var 남은재고기간 = 0;
								for(var ele of forecastSeries){
									if(가용재고 >= ele){
										가용재고 -= ele
										남은재고기간++
									} else {
										break
									}
								}

								if(남은재고기간 >= 입고기간+최대재고기간){
									var maxPeriod = 남은재고기간;
								} else {
									maxPeriod = 입고기간+최대재고기간;
								}
								var dates = this.getMonthDates(salesHistory.length,maxPeriod);
								if(f.타겟키워드){
									var 타겟키워드 = f.타겟키워드;
									if(dates !== f.dates && this.clientId && this.clientSecret){
										var keywordForecast = await this.getKeywordData(dates,타겟키워드);
										var forecastAI = await this.getForecastAI(keywordForecast,f);
									} else {
										keywordForecast = f.keywordForecast;
										forecastAI = f.forecastAI;
									}
								} else {
									타겟키워드 = '';
									keywordForecast = [];
									forecastAI = [];
								}

							} else {
								타겟키워드 = '';
								입고기간 = 17;
								최대재고기간 = 30;
								최소재고기간 = 6;
								keywordForecast = [];
								forecastAI = [];
								가용재고 = 재고;
								남은재고기간 = 0;
								for(ele of forecastSeries){
									if(가용재고 >= ele){
										가용재고 -= ele
										남은재고기간++
									} else {
										break
									}
								}
								if(남은재고기간 >= 입고기간+최대재고기간){
									maxPeriod = 남은재고기간;
								} else {
									maxPeriod = 입고기간+최대재고기간;
								}
								dates = this.getMonthDates(salesHistory.length,maxPeriod);
								구매 = 0;
								배대지 = 0;
								입고중 = 0;
								국내재고 = 0;
								메모 = '';
							}

							가용재고 = 재고 + 구매 + 배대지 + 입고중 + 국내재고;
							if(forecastAI.length > 0){
								남은재고기간 = 0;
								for(ele of forecastAI){
									if(가용재고 >= ele){
										가용재고 -= ele
										남은재고기간++
									} else {
										break
									}
								}
							} else {
								남은재고기간 = 0;
								for(ele of forecastSeries){
									if(가용재고 >= ele){
										가용재고 -= ele
										남은재고기간++
									} else {
										break
									}
								}
							}
							// 입고기간 + 최대재고기간의 판매예상 수량이 있어야함.
							// 구매필요수량 : 입고기간 + 최대재고기간 중 "최대재고기간 - (남은재고기간 - 입고기간)"의 뒷부분 기간에 해당하는 예상수량의 합.

                                                                        /////////////////////////////////////////////////////////////////////// 최대재고기간
																					/////////////////////////////////////////////////////////// 최대재고기간 - 최소재고기간
							//////////////////////// --> 입고기간       ///////////// --> 최소재고기간
							////////////////////////////////////////////////////////// --> 남은재고기간
							var forecastSeriesQty = forecastSeries.reduce((pv,cv) => {return pv + cv},0)
							var forecastAIQty = forecastAI.reduce((pv,cv) => {return pv + cv},0)

							if(남은재고기간 >= 최대재고기간){
								var 구매필요수량 = 0;
							} else {
								if(남은재고기간 >= 0){
									if(forecastAI.length > 0){
										구매필요수량 = parseInt(forecastAI.slice(남은재고기간,최대재고기간).reduce((pv,cv)=>{return pv+cv},0))
									} else {
										구매필요수량 = parseInt(forecastSeries.slice(남은재고기간,최대재고기간).reduce((pv,cv)=>{return pv+cv},0))
									}
								} else {
									구매필요수량 = 0;
								}
							}

							if(일판매수량 > 0 && 구매필요수량 > 0){
								var dday = -남은재고기간+입고기간+최소재고기간;
							} else {
								dday = -999
							}
							var chart = {
								series: [
									{
										name: '예상 판매수량(series)',
										data: salesHistory.concat(forecastSeries).map(val => val || 0),
										type: 'line',
										yAxisIndex: 0
									},
									{
										name: '예상 판매수량(AI+키워드추세)',
										data: salesHistory.concat(forecastAI).map(val => val || 0),
										type: 'line',
										yAxisIndex: 0
									},
									{
										name: '방문자수',
										data: visitorHistory.map(val => val || 0),
										type: 'line',
										yAxisIndex: 1
									},
									{
										name: '페이지뷰',
										data: pageViewHistory.map(val => val || 0),
										type: 'line',
										yAxisIndex: 1
									},
									{
										name: '장바구니',
										data: cartHistory.map(val => val || 0),
										type: 'line',
										yAxisIndex: 1
									},
									{
										name: '주문수',
										data: orderHistory.map(val => val || 0),
										type: 'line',
										yAxisIndex: 1
									},
									{
										name: '전환율(%)',
										data: pvToOrder.map(val => val || 0),
										type: 'line',
										yAxisIndex: 2
									}
								],
								chart: {
									type: 'line',
									height: 500,
									width: 1200,
									animations: {
										enabled: false
									}
								},
								colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a'],
								stroke: {
									width: [2, 2, 2, 2, 2, 2, 2],
									curve: 'smooth'
								},
								dataLabels: {
									enabled: false
								},
								markers: {
									size: 3
								},
								xaxis: {
									categories: dates.map(date => date || ''),
									title: {
										text: '날짜'
									}
								},
								yaxis: [
									{
										min: 0,
										max: Math.max(...[
											...salesHistory,
											...salesHistory.concat(forecastSeries),
											...salesHistory.concat(forecastAI)
										].map(val => val || 0)) * 1.1 || 100,
										title: {
											text: '판매수량'
										},
										labels: {
											formatter: function(val) {
												return val ? Math.round(val).toString() : '0'
											}
										}
									},
									{
										min: 0,
										max: Math.max(...[
											...visitorHistory,
											...pageViewHistory,
											...cartHistory,
											...orderHistory
										].map(val => val || 0)) * 1.1 || 100,
										title: {
											text: '트래픽/주문'
										},
										opposite: true,
										labels: {
											formatter: function(val) {
												return val ? Math.round(val).toString() : '0'
											}
										}
									},
									{
										opposite: true,
										min: 0,
										max: Math.max(...pvToOrder.map(val => val || 0)) * 1.1 || 1,
										title: {
											text: '전환율(%)',
											offsetX: -20
										},
										labels: {
											formatter: function(val) {
												return typeof val === 'number' ? val.toFixed(2) + '%' : '0%'
											},
											offsetX: -30
										},
										tickAmount: 5,
										forceNiceScale: true,
										decimalsInFloat: 2
									}
								],
								tooltip: {
									shared: true,
									intersect: false,
									y: [{
										formatter: function(y) {
											return typeof y === 'number' ? Math.round(y).toString() : '0'
										}
									}, {
										formatter: function(y) {
											return typeof y === 'number' ? Math.round(y).toString() : '0'
										}
									}, {
										formatter: function(y) {
											return typeof y === 'number' ? Math.round(y).toString() : '0'
										}
									}, {
										formatter: function(y) {
											return typeof y === 'number' ? Math.round(y).toString() : '0'
										}
									}, {
										formatter: function(y) {
											return typeof y === 'number' ? Math.round(y).toString() : '0'
										}
									}, {
										formatter: function(y) {
											return typeof y === 'number' ? Math.round(y).toString() : '0'
										}
									}, {
										formatter: function(y) {
											return typeof y === 'number' ? y.toFixed(2) + '%' : '0%'
										}
									}]
								},
								legend: {
									position: 'top',
									horizontalAlign: 'left'
								}
							}

							sales.push({
								썸네일:e.imageUrl,
								판매상품명:e.vendorInventoryName,
								옵션명:e.vendorInventoryItemName,
								productId:e.productId,
								vendorItemId:e.vendorItemId,
								바코드:e.barcode,
								dates:dates,
								dday:dday,
								구매필요수량:구매필요수량,
								구매:구매,
								배대지:배대지,
								입고중:입고중,
								국내재고:국내재고,
								재고:재고,
								입고기간:입고기간,
								최대재고기간:최대재고기간,
								최소재고기간:최소재고기간,
								월판매수량:월판매수량,
								일판매수량:일판매수량,
								chart:chart,
								salesHistory:salesHistory,
								visitorHistory:visitorHistory,
								pageViewHistory:pageViewHistory,
								cartHistory:cartHistory,
								orderHistory:orderHistory,
								pvToOrder:pvToOrder,
								prevMonthlyClicks:prevMonthlyClicks,
								currentMonthlyClicks:currentMonthlyClicks,
								forecastSeries:forecastSeries,
								forecastAI:forecastAI,
								keywordForecast:keywordForecast,
								forecastSeriesQty:forecastSeriesQty,
								forecastAIQty:forecastAIQty,
								남은재고기간:남은재고기간,
								info:e,
								타겟키워드:타겟키워드,
								메모:메모
							})
						}

						console.log(sales)
						try{
							var result = await axios.post('/api/updateGrowthSales',sales);
							if(result.data.res == 'success'){
								this.$store.commit('growthSalesUpdate',sales)
								this.searchresult(sales);
							} else {
								alert(result.data.alert)
								this.showspinner1 = false;
								this.showlist = false;
							}
						}catch(err){
							alert(err)
							this.showspinner1 = false;
							this.showlist = false;
						}
					} else {
						alert('수집된 항목이 없습니다.')
						this.showspinner1 = false;
						this.showlist = false;
					}
                } else {
                    alert(event.data.res)
					this.showspinner1 = false;
					this.showlist = false;
                }
            }
            if (event.data.res && event.data.res === 'growthdbratio'){
                if(event.data.ratio == 0){
                    this.loadingtext = event.data.text
                } else {
                    this.loadingtext = event.data.text + event.data.ratio.toFixed(0) + '%'
                }
            }
		}
		window.addEventListener('message', this.messageEventHandler);
	},
	beforeDestroy() {
		window.removeEventListener('message', this.messageEventHandler);
	},
	methods: {
		editlists(){
			if(this.tableSelectedItem.length == 0){
				alert('변경할 내역을 선택해 주세요.')
				return
			}
			for(var e of this.tableSelectedItem){
				if(this.입고기간){
					e.입고기간 = this.입고기간;
				}
				if(this.최소재고기간){
					e.최소재고기간 = this.최소재고기간
				}
				if(this.최대재고기간){
					e.최대재고기간 = this.최대재고기간
				}
				var index = this.saleslists.findIndex(f => f.바코드 === e.바코드);
				this.changePo(e,index);
			}
			this.입고기간 = '';
			this.최소재고기간 = '';
			this.최대재고기간 = '';
		},
		async saveSales(){
			this.updateSaleslist(this.saleslists);
		},
		async changeMemo(event,saleslist){
			if(event.keyCode == 13){
				this.updateSaleslist([saleslist])
			}
		},
		async updateSaleslist(arr){
			console.log(arr)
			try{
				var result = await axios.post('/api/updateGrowthSales',arr);
				if(result.data.res == 'success'){
					alert('저장 완료')
				} else {
					alert(result.data.alert)
					console.log(result.data)
				}
			}catch(err){
				alert(err)
				console.log(result.data)
			}
		},
		async getKeywordData(dates,keyword){
			var newDates = [];
			for(var e of dates){
				var date = new Date(e);
				date.setFullYear(date.getFullYear() - 1);
				newDates.push(date.toISOString().split('T')[0])
			}
			try{
				var result = await axios.post('/api/getDatalabApi',{clientId:this.clientId,clientSecret:this.clientSecret,dates:newDates,keyword:keyword});
				console.log(result)
				if(result.data.요청결과 == 'success'){
					console.log(result.data.result.results[0].data);
					return result.data.result.results[0].data
				} else {
					console.log(result.data.result);
					return []
				}
			}catch(e){
				console.log(e)
				return []
			}
		},
		async getForecastAI(keywordForecast,f){
			// 0이 아닌 첫 번째 요소의 인덱스를 찾는다.
			let startIndex = f.salesHistory.findIndex(element => element !== 0);

			// 0이 아닌 요소가 없는 경우 (모든 요소가 0인 경우)
			if (startIndex === -1) {
				var salesMean = 0;
			} else {
				// startIndex부터 배열 끝까지의 요소들의 합계
				let sum = 0;
				for (let i = startIndex; i < f.salesHistory.length; i++) {
					sum += f.salesHistory[i];
				}

				// 평균을 계산한다
				salesMean = sum / (f.salesHistory.length - startIndex);
			}
			var keywordMean = keywordForecast.slice(0,f.salesHistory.length).reduce((pv,cv)=>{return pv + cv.ratio},0)/keywordForecast.slice(0,f.salesHistory.length).length;
			var ref = salesMean/keywordMean;
			var forecastAI = [];
			keywordForecast.slice(f.salesHistory.length-1).forEach(e => {
				forecastAI.push(e.ratio*ref)
			})
			return forecastAI
		},
		async saveAPI(){
			try{
				var result = await axios.post('/api/setDatalabApi',{clientId:this.clientId,clientSecret:this.clientSecret});
				if(result.data.요청결과 == 'success'){
					var obj = this.deepClone(this.$store.state.settings[0]);
					obj.clientId = this.clientId;
					obj.clientSecret = this.clientSecret;
					this.$store.commit('settingsupdate',[obj])
					alert('저장완료')
				} else {
					alert('저장실패(510)');
					console.log(result.data.result);
				}
			}catch(e){
				alert('저장실패(514)')
				console.log(e)
			}
		},
		styleDday(dday){
			if(dday > 0){
				return "width:80px;overflow:hidden;text-overflow: ellipsis;color:red;font-weight:bold"
			} else {
				return "width:80px;overflow:hidden;text-overflow: ellipsis;"
			}
		},
		changePoEnter(event,saleslist){
            const index = this.saleslists.findIndex(i => i.바코드 === saleslist.바코드);
			if(event.keyCode === 13){
				this.changePo(saleslist,index);
			}
		},
		async changePo(saleslist, index) {
			saleslist.구매 = parseInt(saleslist.구매)
			saleslist.배대지 = parseInt(saleslist.배대지)
			saleslist.입고중 = parseInt(saleslist.입고중)
			saleslist.국내재고 = parseInt(saleslist.국내재고)
			console.log(saleslist)
			console.log(this.saleslistsinit[index].타겟키워드 !== saleslist.타겟키워드)
			
			// 구매, 배대지, 입고중, 입고기간, 최대재고기간, 최소재고기간, 타겟키워드 변경
			if(saleslist.타겟키워드) {
				if(this.saleslistsinit[index].타겟키워드 !== saleslist.타겟키워드) {
					console.log(saleslist)
					saleslist.keywordForecast = await this.getKeywordData(saleslist.dates, saleslist.타겟키워드);
					saleslist.forecastAI = await this.getForecastAI(saleslist.keywordForecast, saleslist);
					saleslist.chart.series[1].data = saleslist.salesHistory.concat(saleslist.forecastAI);
					this.saleslistsinit[index].타겟키워드 = saleslist.타겟키워드;
				}
			} else {
				saleslist.keywordForecast = [];
				saleslist.forecastAI = [];
				saleslist.chart.series[1].data = [];
			}

			saleslist.forecastAIQty = saleslist.forecastAI.reduce((pv, cv) => pv + cv, 0)

			// 모든 관련 값들을 업데이트
			this.updateItemValues(saleslist);

			// ApexCharts 인스턴스 업데이트 (필요한 경우)
			this.$nextTick(() => {
				if (this.$refs.mainChart && this.$refs.mainChart.chart) {
					this.$refs.mainChart.chart.updateOptions(saleslist.chart);
				}
			});
		},
		getMonthDates(prev, period) {
			const dateArray = [];
			const today = new Date();

			// prev 일 전의 날짜부터 시작
			let startDate = new Date(today);
			startDate.setDate(today.getDate() - prev);

			for (let i = 0; i < prev+period; i++) {
				let date = new Date(startDate);
				date.setDate(startDate.getDate() + i);
				dateArray.push(date.toISOString().split('T')[0]);
			}

			return dateArray;
		},
		collectGrowthSales() {
            window.postMessage({ greeting: "collectGrowthSales", companyid: this.$store.state.user.companyid, suppliercode: this.$store.state.user.suppliercode, growthSales:this.$store.state.growthSales}, "*",)
            this.showspinner1 = true;
            this.showlist = false;
        },
		changeleadtime(saleslist){
			var obj = {
				바코드 : saleslist.바코드,
				입고리드타임 : saleslist.입고리드타임
			}
			if(this.$store.state.user.companyid){
				axios.post('/api/ai/updateleadtime',{companyid:this.$store.state.user.companyid, 바코드:obj.바코드, 입고리드타임:obj.입고리드타임})
				.then(result => {
					console.log(result);
					if(result.data.요청결과 == 'ok'){
						this.$store.commit('updateleadtime',obj)
					} else {
						alert('저장실패(276)');console.log(result)
					}
				})
				.catch(e => {alert('저장실패(279)');console.log(e)})
			}
		},
		clickleadtime(saleslist){
			if(saleslist.showleadtime){
				saleslist.showleadtime = false;
			}
		},
		shownostock(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.재고 == 0);
			setTimeout(()=>{this.showlist = true;},10)
		},
		showtotal(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit;
			setTimeout(()=>{this.showlist = true;},10)
		},
		showMarginAmount(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.수익 > 0)
			setTimeout(()=>{this.showlist = true;},10)
		},
		showSalesAmount(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.판매매출 > 0)
			setTimeout(()=>{this.showlist = true;},10)
		},
		showimportcnt(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.입고 > 0)
			setTimeout(()=>{this.showlist = true;},10)
		},
		showsalescnt(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.판매 > 0)
			setTimeout(()=>{this.showlist = true;},10)
		},
		showNeedPo(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.구매필요수량 > 0)
			setTimeout(()=>{this.showlist = true;},10)
		},
		showNeedPriceAdjust(){
			this.showlist = false;
			this.saleslists = this.saleslistsinit.filter(e => e.가격조정필요 === true)
			setTimeout(()=>{this.showlist = true;},10)
		},
		modalID(index) {
            // console.log(index);
            return 'modal' + index
        },
		salesdownload(){
			if(this.salesarr.length == 0){
				alert('다운로드할 내역이 없습니다.')
				return
			}
			for(var i=0;i<this.salesarr.length;i++){
				// console.log(i)
				this.salesarr[i]['총합계'] = 0;
				this.stockarr[i]['총합계'] = 0;
				this.importarr[i]['총합계'] = 0;
				Object.keys(this.salesarr[i]).forEach(e => {
					if(e !== 'SKUID' && e !== '바코드' && e !== '판매상품명' && e !== '총합계'){
						this.salesarr[i].총합계 += parseInt(this.salesarr[i][e]);
						this.stockarr[i].총합계 += parseInt(this.salesarr[i][e]);
						this.importarr[i].총합계 += parseInt(this.salesarr[i][e]);
					}
				})
			}
			this.salesarr.sort(function(a,b){
				if (a.총합계 < b.총합계){
					return 1;
				}
				if (a.총합계 > b.총합계){
					return -1;
				}
				return 0;
			})
			this.stockarr.sort(function(a,b){
				if (a.총합계 < b.총합계){
					return 1;
				}
				if (a.총합계 > b.총합계){
					return -1;
				}
				return 0;
			})
			this.importarr.sort(function(a,b){
				if (a.총합계 < b.총합계){
					return 1;
				}
				if (a.총합계 > b.총합계){
					return -1;
				}
				return 0;
			})

			this.stockarr.forEach(e => {
				delete e.총합계
			})
			this.importarr.forEach(e => {
				delete e.총합계
			})
			const workBook = XLSX.utils.book_new()
			var myHeader = Object.keys(this.salesarr[0]);
			var myHeader2 = myHeader.filter(e => e !== '총합계');
			const workSheet1 = XLSX.utils.json_to_sheet(this.salesarr, {header : myHeader})
			XLSX.utils.book_append_sheet(workBook, workSheet1, '판매현황')
			const workSheet2 = XLSX.utils.json_to_sheet(this.stockarr, {header : myHeader2})
			XLSX.utils.book_append_sheet(workBook, workSheet2, '재고현황')
			const workSheet3 = XLSX.utils.json_to_sheet(this.importarr, {header : myHeader2})
			XLSX.utils.book_append_sheet(workBook, workSheet3, '입고현황')
			XLSX.writeFile(workBook, '일자별판매현황' + '_' + this.startdate + '-' + this.enddate + '.xlsx')
		},
		async search(){
			this.showspinner1 = true;
			this.loadingtext = '로켓그로스 판매현황을 불러오는 중입니다.'

			if(this.$store.state.growthSales.length > 0){
				this.searchresult(this.$store.state.growthSales);
			} else {
				try{
					if(this.$store.state.growthdb.length == 0){
						try{
							var result = await axios.post('/api/getGrowthDB',{show:true});
							this.$store.commit('growthdbupdate', result.data.growthdb);
						}catch(e){
							console.log('로켓그로스 상품DB 불러오기 실패')
							console.log(e)
						}                
					}
					result = await axios.post('/api/getGrowthSales');
					if(result.data.res == 'success'){
						if(this.$store.state.growthdb.length > 0){
							var growthSales = result.data.growthSales.filter(e => this.$store.state.growthdb?.find(f => f.vendorItemId == e.vendorItemId)?.show);
						} else {
							growthSales = result.data.growthSales
						}

						this.$store.commit('growthSalesUpdate',growthSales);
						this.searchresult(growthSales);
					} else {
						alert('로켓그로스 판매현황 가져오기 실패')
						console.log(result.data)
					}
				} catch(e) {
					alert('로켓그로스 판매현황 가져오기 실패')
					console.log(e)
				}
			}
		},
		datetotimestamp(a){
			var date = new Date(a);
			return date.getTime();
		},
		searchresult(arr){
			if(arr.length == 0){
				alert('로켓그로스 판매현황이 없습니다.')
				return
			}
			this.needPo = 0;
			this.saleslists = [];
			this.saleslistsinit = [];
			for(var e of arr){
				if(e.dday > 0){
					this.needPo += e.구매필요수량;
				}
				e.chart.chart.animations = {
					enabled: false // 애니메이션 비활성화
				}
				if (!e.startDate) {
					e.startDate = e.dates[0];
				}
				if (!e.endDate) {
					e.endDate = e.dates[e.dates.length - 1];
				}
				e.국내재고 = e.국내재고 ? e.국내재고 : 0;
				this.saleslists.push(this.deepClone(e))
				this.saleslistsinit.push(this.deepClone(e))
			}

			this.needPo = arr.reduce((pv,cv) => {
				if(cv.dday > 0){
					return pv + cv.구매필요수량
				} else {
					return pv
				}
			},0)

			console.log(this.saleslists)
			this.$nextTick(() => {
				this.showlist = true;
				this.showspinner = false;
				this.showspinner1 = false;
				this.loadingtext = '';
			})
			
		},
		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},
		todate(date){
			var today = new Date(date);
			today.setHours(today.getHours() + 9);
			return today.toISOString().replace('T', ' ').substring(0, 19);
		},
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime()
        },
		getTodaywith(){
			var date = new Date();
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},
		timestamptodate(timestamp){
			var date = new Date(timestamp);
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},
		downloadsales() {
			function getToday(){
				var date = new Date();
				var year = date.getFullYear();
				var month = ("0" + (1 + date.getMonth())).slice(-2);
				var day = ("0" + date.getDate()).slice(-2);

				return year + month + day;
			}
			var arr = [];
			var obj = {};
			console.log(this.saleslists)
			this.saleslists.forEach(e => {
				obj = {
					SKUID : e.SKUID,
					바코드 : e.바코드,
					상품명 : e.판매상품명,
					옵션명 : e.옵션명,
					발주가능상태 : e.발주가능상태,
					구매필요수량 : e.구매필요수량,
					안전재고 : e.안전재고,
					쿠팡내재고 : e.재고,
					출고 : e.판매,
					입고리드타임 : e.입고리드타임,
					// 재고기준일 : e.재고기준일,
					일판매수량 : e.일판매수량
				}
				arr.push(obj)
			})
			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(arr)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '발주수요예측')
			XLSX.writeFile(workBook, '발주수요예측' + '_' + getToday() + '.xlsx')
		},
		setDateRange(item, days) {
			const today = new Date();
			const yesterday = new Date(today);
			yesterday.setDate(today.getDate() - 1);
			
			const startDate = new Date(yesterday);
			startDate.setDate(yesterday.getDate() - days + 1);

			item.endDate = this.formatDate(yesterday);
			item.startDate = this.formatDate(startDate);

			this.updateDateRange(item);
		},

		formatDate(date) {
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			return `${year}-${month}-${day}`;
		},
		updateDateRange(item) {
			if (item.startDate && item.endDate) {
				const startIndex = item.dates.indexOf(item.startDate);
				const endIndex = item.dates.indexOf(item.endDate);
				
				if (startIndex !== -1 && endIndex !== -1) {
					const filteredSalesHistory = item.salesHistory.slice(startIndex, endIndex + 1);
					
					// 일평균 판매량 재계산
					item.일판매수량 = filteredSalesHistory.reduce((sum, val) => sum + val, 0) / filteredSalesHistory.length;

					// 차트 데이터 업데이트 (전체 데이터 유지)
					item.chart.series = [
						{
							name: '실제 판매량',
							data: item.salesHistory
						},
						{
							name: '예상 판매량(series)',
							data: item.salesHistory.concat(item.forecastSeries)
						},
						{
							name: '예상 판매량(AI+키워드추세)',
							data: item.salesHistory.concat(item.forecastAI)
						},
						{
							name: '방문자수',
							data: item.visitorHistory,
							type: 'line'
						},
						{
							name: '페이지뷰',
							data: item.pageViewHistory,
							type: 'line'
						},
						{
							name: '장바구니',
							data: item.cartHistory,
							type: 'line'
						},
						{
							name: '주문수',
							data: item.orderHistory,
							type: 'line'
						},
						{
							name: '전환율(%)',
							data: item.pvToOrder,
							type: 'line',
							yAxisIndex: 1 // Using secondary y-axis
						}
					];
					
					// X축 범위 설정
					item.chart.xaxis = {
						...item.chart.xaxis,
						min: item.startDate,
						max: item.endDate,
						categories: item.dates
					};

					// 선택된 기간에 맞춰 예측 데이터 업데이트
					const daysToForecast = 35; // 또는 필요한 예측 일수
					item.forecastSeries = this.calculateForecast(filteredSalesHistory, daysToForecast);
					item.forecastAI = item.forecastAI.length > 0 ? this.calculateForecast(filteredSalesHistory, daysToForecast) : [];

					// 다른 값들 업데이트
					this.updateItemValues(item);

					// ApexCharts 인스턴스 업데이트
					this.$nextTick(() => {
						if (this.$refs.mainChart && this.$refs.mainChart.chart) {
							this.$refs.mainChart.chart.updateOptions(item.chart);
						}
					});
				}
			}
		},
		updateItemValues(item) {
			// 가용재고 계산
			const 가용재고 = item.재고 + item.구매 + item.배대지 + item.입고중 + item.국내재고;

			// 남은재고기간 계산
			let 남은재고기간 = 0;
			let 잔여가용재고 = 가용재고;
			const forecastArray = item.forecastAI.length > 0 ? item.forecastAI : item.forecastSeries;

			for (const 예상판매량 of forecastArray) {
				if (잔여가용재고 >= 예상판매량) {
					잔여가용재고 -= 예상판매량;
					남은재고기간++;
				} else {
					break;
				}
			}

			item.남은재고기간 = 남은재고기간;

			// 구매필요수량 계산
			if (남은재고기간 >= parseInt(item.최대재고기간)) {
				item.구매필요수량 = 0;
			} else {
				const sliceStart = Math.max(0, 남은재고기간);
				const sliceEnd = parseInt(item.최대재고기간);
				item.구매필요수량 = parseInt(forecastArray.slice(sliceStart, sliceEnd).reduce((sum, val) => sum + val, 0));
			}

			// 구매D-Day 계산 (수정된 부분)
			if (item.일판매수량 > 0) {
				const 안전재고일수 = parseInt(item.최소재고기간);
				const 입고소요일수 = parseInt(item.입고기간);
				const 안전재고량 = item.일판매수량 * 안전재고일수;

				if (가용재고 <= 안전재고량) {
					item.dday = 입고소요일수; // 즉시 주문 필요
				} else {
					const 초과재고 = 가용재고 - 안전재고량;
					const 초과재고소진일수 = Math.floor(초과재고 / item.일판매수량);
					item.dday = 입고소요일수 - 초과재고소진일수;
				}
			} else {
				item.dday = -999; // 판매량이 없는 경우
			}

			// 향후 판매수량 업데이트
			item.forecastSeriesQty = item.forecastSeries.reduce((sum, val) => sum + val, 0);
			item.forecastAIQty = item.forecastAI.reduce((sum, val) => sum + val, 0);

			// 월판매수량 업데이트 (최근 30일 기준)
			const recentSales = item.salesHistory.slice(-30);
			item.월판매수량 = recentSales.reduce((sum, val) => sum + val, 0);
		},
		getUpdatedChartOption(item) {
			const validateData = (data) => {
				if (!Array.isArray(data)) return Array(30).fill(0);
				return data.map(val => {
					const num = Number(val);
					return isNaN(num) ? 0 : num;
				});
			};

			const salesHistory = validateData(item.salesHistory);
			const visitorHistory = validateData(item.visitorHistory);
			const pageViewHistory = validateData(item.pageViewHistory);
			const cartHistory = validateData(item.cartHistory);
			const orderHistory = validateData(item.orderHistory);
			const pvToOrder = validateData(item.pvToOrder);
			const forecastSeries = validateData(item.forecastSeries);
			const forecastAI = validateData(item.forecastAI);

			const dates = Array.isArray(item.dates) 
				? item.dates.map(d => d?.slice(-5)?.replace("-","/") || '')
				: Array(30).fill('');

			const salesMax = Math.max(
				...salesHistory,
				...forecastSeries,
				...forecastAI,
				1
			) * 1.1;

			const trafficMax = Math.max(
				...visitorHistory,
				...pageViewHistory,
				...cartHistory,
				...orderHistory,
				1
			) * 1.1;

			const conversionMax = Math.max(
				...pvToOrder,
				0.1
			) * 1.1;

			return {
				series: [
					{
						name: '판매량',
						data: salesHistory.concat(forecastAI).map(val => val || 0),
						type: 'column'
					},
					{
						name: '방문자수',
						type: 'line',
						data: visitorHistory.map(val => val || 0)
					},
					{
						name: '페이지뷰',
						type: 'line',
						data: pageViewHistory.map(val => val || 0)
					},
					{
						name: '장바구니',
						type: 'line',
						data: cartHistory.map(val => val || 0)
					},
					{
						name: '주문건수',
						type: 'line',
						data: orderHistory.map(val => val || 0)
					},
					{
						name: '전환율(%)',
						type: 'line',
						data: pvToOrder.map(val => val || 0)
					}
				],
				chart: {
					height: 500,
					animations: { enabled: false },
					toolbar: {
						show: true
					},
					parentHeightOffset: 0,   // 부모 컨테이너와의 간격 제거
					offsetY: 0   
				},
				plotOptions: {
					bar: {
						columnWidth: '30%',  // bar 너비 조절
						opacity: 0.4         // bar 투명도 조절
					}
				},
				stroke: {
					width: [0, 3, 3, 3, 3, 3],  // line 그래프 두께 증가
					curve: 'smooth'
				},
				colors: [
					'rgba(0, 143, 251, 0.5)',   // bar 차트 색상 (투명도 포함)
					'#00E396', 
					'#FEB019', 
					'#FF4560', 
					'#775DD0', 
					'#26a69a'
				],
				markers: {
					size: [0, 0, 0, 0, 0, 6],   // 전환율만 마커 표시
					colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#26a69a'],
					strokeColors: '#fff',
					strokeWidth: 2,
					hover: {
						size: 8
					}
				},
				xaxis: {
					categories: dates,
					labels: {
						style: { fontSize: '12px' }
					}
				},
				yaxis: [
					{
						seriesName: '판매량',
						title: { 
							text: '판매수량',
							offsetX:0,
							offsetY:-10
						},
						min: 0,
						max: salesMax,
						labels: {
							formatter: (val) => Math.round(val).toString()
						}
					},
					{
						seriesName: ['방문자수', '페이지뷰', '장바구니', '주문건수'],
						title: { text: '트래픽/주문' },
						min: 0,
						max: trafficMax,
						opposite: true,
						labels: {
							formatter: (val) => Math.round(val).toString()
						},
						show: true
					},
					{
						seriesName: '전환율(%)',
						title: {
							text: '전환율(%)',
							offsetX: -20
						},
						min: 0,
						max: conversionMax,
						opposite: true,
						labels: {
							formatter: (val) => val.toFixed(2) + '%',
							offsetX: -30
						},
						tickAmount: 5
					}
				],
				tooltip: {
					enabled: true,
					shared: true,
					intersect: false,
					followCursor: true,
					x: {
						show: true
					},
					y: [
						{
							title: {
								formatter: () => "판매량: "
							},
							formatter: (val) => val ? Math.round(val).toString() : '0'
						},
						{
							title: {
								formatter: () => "방문자수: "
							},
							formatter: (val) => val ? Math.round(val).toString() : '0'
						},
						{
							title: {
								formatter: () => "페이지뷰: "
							},
							formatter: (val) => val ? Math.round(val).toString() : '0'
						},
						{
							title: {
								formatter: () => "장바구니: "
							},
							formatter: (val) => val ? Math.round(val).toString() : '0'
						},
						{
							title: {
								formatter: () => "주문건수: "
							},
							formatter: (val) => val ? Math.round(val).toString() : '0'
						},
						{
							title: {
								formatter: () => "전환율: "
							},
							formatter: (val) => val ? val.toFixed(2) + '%' : '0%'
						}
					],
					marker: {
						show: true
					},
					fixed: {
						enabled: false,
						position: 'topRight'
					}
				},
				dataLabels: { enabled: false },
				legend: {
					position: 'top',           // legend 위치를 상단으로
					horizontalAlign: 'center', // 가운데 정렬
					offsetY: 0,               // 상단 여백
					offsetX: 0,               // 좌우 여백
					floating: true,           // legend를 차트 영역 위에 띄움
					itemMargin: {
						horizontal: 15,
						vertical: 5
					},
					height: 30,              // legend 영역 높이
					fontSize: '13px',
					markers: {
						width: 12,
						height: 12,
						strokeWidth: 0,
						radius: 12
					}
				},
				grid: {
					padding: {
						top: 30,    // 상단 여백 추가
						bottom: 10   // 하단 여백
					}
				},
			};
		},
		resetChartData(item) {
			if (!item || !item.chart) return;

			const chartOption = this.getUpdatedChartOption(item);
			
			this.$nextTick(() => {
				if (this.$refs.mainChart && this.$refs.mainChart.chart) {
					this.$refs.mainChart.chart.updateOptions(chartOption);
				}
			});
		},
		calculateForecast(salesHistory, days) {
			const avgSales = salesHistory.reduce((sum, val) => sum + val, 0) / salesHistory.length;
			return Array(days).fill(avgSales);
		},
		closeModal(event,item){
			event.preventDefault();
			if (this.$refs.mainChart && this.$refs.mainChart.chart) {
                this.$refs.mainChart.chart.destroy();
            }
			this.$nextTick(() => {
				this.$bvModal.hide('poQty'+item.바코드)
			});
		}
	}

}
</script>

<style>
.my-class .dropdown-menu {

max-height: 300px;
width:800px;
overflow-y: auto;
}
.modal-dialog {
    max-width: 90%;
    position: absolute;

    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>
